import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.scss']
})
export class LearningComponent implements OnInit {
  cardLearning = [
    {
      text: 'Jadwal',
      icon: 'far fa-fw fa-calendar-alt',
      link: '#'
    },
    {
      text: 'Podcast',
      icon: 'fas fa-fw fa-podcast',
      link: '#'
    },
    {
      text: 'Unduh',
      icon: 'fas fa-fw fa-download',
      link: '#'
    }
  ];
  constructor() { }

  ngOnInit() {
  }

}
