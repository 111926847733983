<section class="events bg-grey section-padding px-sm-0 px-3">

  <div class="container">

    <div class="mb-5 text-center">
      <h2 class="section-title text-primary-1">Aktivitas dan Kegiatan ICCN</h2>
      <p>Aktivitas dan kegiatan ICCN</p>
    </div>

    <div class="elfsight-app-db96dbc2-8767-4202-bc2b-3c0614f5a936 ig-feeds"></div>

    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4" *ngFor="let event of eventList">
        <div class="card card--events mb-4 border-0">
          <div class="card-body p-0">
            <h5 class="card-title">
              <a [routerLink]="['/daftar-acara',event.slug]">{{event.title | shorten: 30: '...'}}</a>
            </h5>
            <div class="card--events__date">
              <span>{{event.place}}</span>
              <span>{{event.eventDate | date:'EEEE, d MMMM yyyy'}}</span>
            </div>
          </div>
          <img [src]="helpers.parseImage(event.eventImg)" class="card--events__img rounded-xl" alt="">
        </div>
      </div>
    </div>

    <div class="mt-5 text-center">
      <a [routerLink]="['/daftar-acara']" class="btn btn-primary rounded-pill">Acara Lainnya</a>
    </div>

  </div>

</section>
