import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abbs',
  templateUrl: './abbs.component.html',
  styleUrls: ['./abbs.component.scss']
})
export class AbbsComponent implements OnInit {

  constructor() { }

  abbsList = [
    {
      name: 'ICS',
      imgPath: 'assets/img/logo/ics.png',
      linkURL: 'https://www.instagram.com/ics.official'
    },
    {
      name: 'AJAR',
      imgPath: 'assets/img/logo/ajar.png',
      linkURL: 'https://youtube.com/playlist?list=PLXs0YaWUVcTdkwPO-xHumaWPOhxeCDN-T'
    },
    {
      name: 'DAMPING',
      imgPath: 'assets/img/logo/damping.png',
      linkURL: 'javaScript:void(0)'
    },
    {
      name: 'PULIH',
      imgPath: 'assets/img/logo/pulih.png',
      linkURL: 'https://youtu.be/8Z0lyGjx1JA'
    },
    {
      name: 'RESCUE',
      imgPath: 'assets/img/logo/rescue.png',
      linkURL: 'javaScript:void(0)'
    }
  ]

  ngOnInit(): void {
  }

}
