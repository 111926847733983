<section class="prinsip section-padding bg-white px-3 px-sm-0">

  <div class="container">

    <div class="row">
      <div class="col-lg-6 order-2 order-lg-1">
        <img src="assets/img/illustration/2_prinsip.png" class="prinsip__img">
      </div>
      <div class="col-lg-6 order-1 order-lg-2 mb-5 mb-lg-0">
        <h2 class="section-title text-primary-1 mb-3">10 Prinsip Kota Kreatif</h2>
        <p>10 Prinsip ini merupakan hasil dari Konferensi Kota Kreatif pada tanggal 27 April 2015 di Kota Bandung</p>
        <ol class="mb-0">
          <li>
            Kota yang welas asih
          </li>
          <li>
            Kota yang inklusif
          </li>
          <li>
            Kota yang melindungi hak asasi manusia
          </li>
          <li>
            Kota yang memuliakan kreativitas masyarakatnya
          </li>
          <li>
            Kota yang tumbuh bersama lingkungan yang lestari
          </li>
          <li>
            Kota yang memelihara kearifan sejarah sekaligus membangun semangat pembaharuan
          </li>
          <li>
            Kota yang dikelola secara transparan, adil dan jujur
          </li>
          <li>
            Kota yang memenuhi kebutuhan dasar masyarakat
          </li>
          <li>
            Kota yang memanfaatkan energi terbarukan
          </li>
          <li>
            Kota yang mampu menyediakan fasilitas umum yang layak untuk masyarakat
          </li>
        </ol>
      </div>
    </div>

  </div>

</section>
