<nav class="topbar bg-grey">
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between px-0 px-sm-3">
      <span class="topbar__page-title">{{pageTitle}}</span>
      <button (click)="logout()" class="btn btn-danger rounded-circle">
        <i class="fas fa-fw fa-power-off"></i>
      </button>
    </div>
  </div>
</nav>
