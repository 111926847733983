import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { News } from 'src/app/services/news';
import { NewsService } from 'src/app/services/news.service';
import { HttpQuery } from 'src/app/services/http-query';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  private subs = new Subject();
  where: News = { isPublish: true};
  newsList: News[] = []
  q: HttpQuery = { limit: 4 , offset: 0, sort: { publishDate: -1 }, where: this.where };

  constructor(
    private newsService: NewsService,
    public helpers: HelperService,
    // public router: Router
  ) {

  }
  ngOnInit() {
    this.getData();
  }

  getData() {
    // this.isLoading = true;
    // this.q.offset = (this.currentPage - 1 ) * this.q.limit;
    // this.q.limit = this.limit
    this.newsService.getPublic(this.q)
    .pipe(takeUntil(this.subs))
    .subscribe(({data, count}) => {
      // this.totalDataNews = count;
      this.newsList = data;
      // this.isLoading = false;
    }, (err) => {
      // this.isLoading = false;
      // this.errorMessage = err.message;
    });
  }
}
