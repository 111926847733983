import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from './helper.service';
import { HttpQuery } from './http-query';
import { EventsAPI ,Events } from './events';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    private http: HttpClient,
    private helpers: HelperService
  ) {}
  endpoint = '/api/events';
  getAll(query: HttpQuery) {
    const url = this.endpoint;
    const params = this.helpers.generateParams(query);
    return this.http.get<EventsAPI>(url, {params});
  }
  getPublic(query: HttpQuery) {
    const url = `${this.endpoint}/public`;
    const params = this.helpers.generateParams(query);
    return this.http.get<EventsAPI>(url, {params});
  }
  getBySlug(slug: string) {
    const url = `${this.endpoint}/slug/${slug}`;
    return this.http.get<Events>(url);
  }
  getById(id: string) {
    const url = `${this.endpoint}/${id}`;
    return this.http.get<Events>(url);
  }

  insert(data: Events) {
    const url = this.endpoint;
    return this.http.post<Events>(url, data);
  }
  updateById(id: string, data: Events) {
    const url = `${this.endpoint}/${id}`;
    return this.http.put<Events>(url, data);
  }
  updateWhere(query: HttpQuery, data: Events) {
    const url = `${this.endpoint}`;
    const params = this.helpers.generateParams(query);
    return this.http.post<Events>(url, data, {params});
  }
  removeById(id: string) {
    const url = `${this.endpoint}/${id}`;
    return this.http.delete<Events>(url);
  }
}
