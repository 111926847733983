import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpQuery } from 'src/app/services/http-query';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { Events } from 'src/app/services/events';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  providers: [      ],
})

export class EventListComponent implements OnInit, OnDestroy {

  private subs = new Subject();
  where: Events = { isPublish: true};
  offset = 0;
  limit = 2;
  sort = 'createdAt';
  order = 'desc';
  totalDataNews = 0;
  currentPage = 1;
  isLoading = false;
  errorMessage: string;
  eventList: Events[] = []
  q: HttpQuery = { limit: 10, offset: 0, sort: { eventDate: -1 }, where: this.where };

  @ViewChild('event') event;

  constructor(
    private eventsService: EventsService,
    public helpers: HelperService,
    public router: Router
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.q.offset = (this.currentPage - 1 ) * this.q.limit;
    this.eventsService.getPublic(this.q)
    .pipe(takeUntil(this.subs))
    .subscribe(({data, count}) => {
      this.totalDataNews = count;
      this.eventList = data;
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      this.errorMessage = err.message;
    });
  }

  ngOnDestroy() {
    this.subs.next();
    this.subs.complete();
  }

}
