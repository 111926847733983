<footer class="footer">

  <div class="footer__desc px-3 px-sm-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 mb-3 mb-lg-0">
          <img src="assets/img/logo/logo_big.png" class="footer__desc__img" alt="ICCN">
        </div>
        <div class="col-lg-3 mb-3 mb-lg-0">
          <p>
            Indonesia Creative Cities Network (ICCN) atau Jejaring Kota/Kabupaten Kreatif Indonesia adalah simpul organisasi yang berkomitmen untuk mewujudkan 10 Prinsip Kota Kreatif. Kini dalam ICCN telah tergabung 211 inisiatif kota/kabupaten di bawah koordinasi 11 Koordinator Daerah yang tergabung dalam Pengurus Pusat ICCN.
          </p>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6 col-lg-5 col-xl-6 mb-4 mb-md-0">
              <h6 class="footer__desc__title">Alamat</h6>
              <div class="footer__desc__address">
                <i class="fas fa-fw fa-map-marker-alt"></i>
                <a href="http://maps.google.com/?daddr=-6.920634,107.609723&amp;ll=" target="_blank">Simpul Space #4 Jalan Braga No. 3 Kota Bandung - 40114, Telp./Fax. +62 22 2503404</a>
              </div>
            </div>
            <div class="col-md-6 col-lg-7 col-xl-6">
              <h6 class="footer__desc__title">Kontak</h6>
              <ul class="footer__desc__contact">
                <li>
                  Instagram: <a href="https://www.instagram.com/iccnmedia" target="_blank" title="ICCN's Instagram"><i class="fab fa-fw fa-instagram"></i>  @iccnmedia</a>
                </li>
                <li>
                  Email: <a href="mailto:creativecitiesid@gmail.com" target="_blank" title="ICCN's Email"><i class="far fa-fw fa-envelope"></i> creativecitiesid@gmail.com</a>
                </li>
                <li>
                  WhatsApp: <a href="https://wa.me/628121405647" target="_blank" title="ICCN's Email"><i class="fab fa-fw fa-whatsapp"></i>  +62 81 2140 5647</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer__copy px-3 px-sm-0">
    <div class="container">
      <p class="footer__copy__text">
        2020 &copy; ICCN (Indonesia Creative City Network). All Rights Reserved.
      </p>
    </div>
  </div>

</footer>
