import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    items: 1,
    margin: 24,
    mouseDrag: false,
    touchDrag: false,
    lazyLoad: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    nav: false,
    autoplay: true,
    autoHeight: true
  };
  constructor() { }

  ngOnInit() {
  }

}
