<h4 class="text-primary-1">ICCN Newsletter</h4>
<p class="mb-0">
  Daftar untuk dapat informasi kegiatan kota kreatif dan ide-ide kreatif untuk solusi kotamu.
</p>
<div class="my-3">
  <form [formGroup]="form" (ngSubmit)="send()" novalidate>
    <div class="input-group">
      <input type="email" class="form-control form-control-lg rounded-xl" placeholder="Email" formControlName="email" [ngClass]="{'is-invalid': helper.isInvalid(form, 'email')}">
      <div class="input-group-append">
        <button class="btn btn-lg btn-primary" type="submit" [disabled]="form.invalid">Kirim</button>
      </div>
      <div class="invalid-feedback" *ngIf="helper.isInvalid(form, 'email')" >
        Email tidak sah
      </div>
    </div>
    <app-recaptcha-dialog *ngIf="showCaptcha" (resolved)="captchaResolved($event)"></app-recaptcha-dialog>
  </form>
</div>
