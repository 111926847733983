import { Component,  OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpQuery } from 'src/app/services/http-query';
import { Router } from '@angular/router';
import { NewsService } from 'src/app/services/news.service'
import { News, NewsAPI} from 'src/app/services/news'
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
  providers: [      ],
})

export class NewsListComponent implements OnInit , OnDestroy{

  private subs = new Subject();
  where: News = { isPublish: true};
  offset = 0;
  limit = 10;
  sort = 'createdAt';
  order = 'desc';
  totalDataNews = 0;
  currentPage = 1;
  isLoading = false;
  errorMessage: string;
  newsList: News[] = []
  q: HttpQuery = { limit: this.limit, offset: 0, sort: { createdAt: -1 }, where: this.where };

  @ViewChild('news') news;

  constructor(
    private newsService: NewsService,
    public helpers: HelperService,
    public router: Router
    ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.q.offset = (this.currentPage - 1 ) * this.q.limit;
    this.newsService.getPublic(this.q)
    .pipe(takeUntil(this.subs))
    .subscribe(({data, count}) => {
      this.totalDataNews = count;
      this.newsList = data;
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      this.errorMessage = err.message;
    });
  }

  ngOnDestroy() {
    this.subs.next();
    this.subs.complete();
  }
}
