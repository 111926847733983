import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menu = [
    {
      url: '/backend/dashboard',
      name: 'Dashboard',
      icon: 'fa-tachometer-alt'
    },
    // {
    //   url: '/backend/home',
    //   name: 'Beranda',
    //   icon: 'fa-home'
    // },
    // {
    //   url: '/backend/collaborations',
    //   name: 'Kolaborasi',
    //   icon: 'fa-hands-helping'
    // },
    // {
    //   url: '/backend/creativecities',
    //   name: 'Kota Kreatif',
    //   icon: 'fa-building'
    // },
    // {
    //   url: '/backend/organization',
    //   name: 'Organisasi',
    //   icon: 'fa-user-friends'
    // },
    // {
    //   url: '/backend/iccf',
    //   name: 'ICCF',
    //   icon: 'fa-calendar-check'
    // },
    {
      url: '/backend/events',
      name: 'Acara',
      icon: 'fa-calendar-alt'
    },
    {
      url: '/backend/news',
      name: 'Berita',
      icon: 'fa-newspaper'
    },
    // {
    //   url: '/backend/shop',
    //   name: 'Toko',
    //   icon: 'fa-shopping-cart'
    // },
    // {
    //   url: '/backend/user',
    //   name: 'Pengguna',
    //   icon: 'fa-users'
    // },
    {
      url: '/backend/settings',
      name: 'Pengaturan',
      icon: 'fa-cogs'
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
