import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RecaptchaDialogComponent } from 'src/app/recaptcha-dialog/recaptcha-dialog.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, OnDestroy {
  private unsubs = new Subject()
  constructor(
    public helper: HelperService,
    private newsletterService: NewsletterService,
    private sb: MatSnackBar,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      captcha: [false]
    })
  }
  showCaptcha = false;
  form: FormGroup
  ngOnInit() {
  }
  send(){
    this.showCaptcha = true;
    if (this.form.valid && this.form.get('captcha').value) {
      this.sendMessage()
    }
  }
  captchaResolved(valid){
    this.form.get('captcha').setValue(valid)
    if(this.form.get('captcha').value){
      this.sendMessage()
    }
  }
  private sendMessage(){
    this.newsletterService.insert(this.form.value).pipe(takeUntil(this.unsubs))
      .subscribe(() => {
        this.sb.open('Anda saat ini berlangganan newsletter!', null,  {duration: 5000})
        this.form.reset()
      }, (err) => {
        if (!err.message){
          let msg = ''
          if (err.errors.length) {
            err.errors.forEach(e => {
              msg += `${e.msg}. `;
            });
          }
          this.sb.open( msg, null, {duration: 5000})
          this.showCaptcha = false
        } else {
          this.showCaptcha = false
          this.sb.open(err.message, null, {duration: 5000})
        }
      })
  }
  ngOnDestroy() {
    this.unsubs.next()
    this.unsubs.complete()
  }
}
