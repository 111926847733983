import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent  {
  message: string;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public md: any
  ) {
    this.message = (this.md && this.md.message) ? this.md.message : 'Apakah anda yakin ingin menghapus data ini?';
  }
  cancel() {
    this.dialogRef.close();
  }
  submit() {
    this.dialogRef.close(true);
  }

}
