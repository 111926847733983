<section class="event-list section-padding">

  <div class="container">

    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4" *ngFor="let event of eventList">
        <div class="card card--events mb-4 border-0">
          <div class="card-body p-0">
            <h5 class="card-title">
              <a [routerLink]="['/events',event.slug]">{{event.title}}</a>
            </h5>
            <div class="card--events__date">
              <span>{{event.place}}</span>
              <span>{{event.eventDate | date:'EEEE, d MMMM yyyy'}}</span>
            </div>
          </div>
          <img [src]="helpers.parseImage(event.eventImg)" class="card--events__img rounded-xl" alt="">
        </div>
      </div>
    </div>

    <nav aria-label="Page navigation example">
      <ul class="pagination custom-pagination justify-content-center align-items-center mt-5">
        <li class="page-item">
          <button class="btn btn-iccn" disabled>
            <i class="fas fa-fw fa-angle-left"></i> Prev
          </button>
        </li>
        <li class="page-item">
          <div class="btn-group" dropdown>
            <button id="pageButton" dropdownToggle type="button" class="btn btn-iccn dropdown-toggle" aria-controls="pageNumber">
              1 <span class="caret"></span>
            </button>
            <ul id="pageNumber" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="pageButton" style="max-height: 150px; overflow-y: scroll;">
              <li role="menuitem"><a class="dropdown-item active" href="#">1</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">2</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">3</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">4</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">5</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">6</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">7</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">8</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">9</a></li>
              <li role="menuitem"><a class="dropdown-item" href="#">10</a></li>
            </ul>
          </div>
        </li>
        <li class="page-item">of 10</li>
        <li class="page-item">
          <button class="btn btn-iccn">
            Next <i class="fas fa-fw fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

  </div>

</section>
