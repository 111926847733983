export const OrganizationList = [
  {
    img: 'assets/img/others/avatar.png',
    name: 'Tb. Fiki C. Satari',
    position: 'Ketua Umum'
  },
  {
    img: 'assets/img/others/avatar.png',
    name: 'M. Arief Budiman',
    position: 'Sekretaris Umum'
  },
  {
    img: 'assets/img/others/avatar.png',
    name: 'Ira Shinta',
    position: 'Bendahara Umum'
  },
  {
    img: 'assets/img/others/avatar.png',
    name: 'Rizka Safitri',
    position: 'Kesekretariatan:'
  },
  {
    img: 'assets/img/others/avatar.png',
    name: 'Robby Herawan',
    position: 'Kesekretariatan:'
  }
];
