import { Component, OnInit } from '@angular/core';
import { latLng, tileLayer, icon, marker, divIcon, circle, polygon } from 'leaflet';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  options = {
    layers: [
      // tslint:disable-next-line: max-line-length
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 20, attribution: `<a href="https://profileimage.studio" target="_blank" >Profile Image Studio</a>` })
    ]
  };
  layers = [
    marker([ -6.920634, 107.609723 ], {
      icon: icon({
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        iconUrl: 'assets/leaflet/marker-icon.png',
        shadowUrl: 'assets/leaflet/marker-shadow.png'
      })
    })
  ];
  center = latLng(-6.920634, 107.609723);
  zoom = 17;
  constructor() { }

  ngOnInit() {

  }

}
