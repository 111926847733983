import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HeroComponent } from './hero/hero.component';
import { RouterModule } from '@angular/router';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { ContactComponent } from './contact/contact.component';
import { NewsComponent } from './news/news.component';
import { EventsComponent } from './events/events.component';
import { OrganizationComponent } from './organization/organization.component';
import { AboutComponent } from './about/about.component';
import { HighlightComponent } from './highlight/highlight.component';
import { EventListComponent } from './event-list/event-list.component';
import { LearningComponent } from './learning/learning.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgPipesModule } from 'ngx-pipes';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CathaComponent } from './catha/catha.component';
import { PrinsipComponent } from './prinsip/prinsip.component';
import { AbbsComponent } from './abbs/abbs.component';
import { NewsListComponent } from './news-list/news-list.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout'
import { RecaptchaDialogModule } from '../recaptcha-dialog/recaptcha-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    HeroComponent,
    AbbsComponent,
    NewsletterComponent,
    ContactComponent,
    NewsComponent,
    EventsComponent,
    OrganizationComponent,
    LearningComponent,
    AboutComponent,
    HighlightComponent,
    EventListComponent,
    OrganizationListComponent,
    CathaComponent,
    PrinsipComponent,
    NewsListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    NgPipesModule,
    CarouselModule,
    LeafletModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RecaptchaDialogModule,
    MatDialogModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    HeroComponent,
    AbbsComponent,
    NewsletterComponent,
    ContactComponent,
    NewsComponent,
    EventsComponent,
    OrganizationComponent,
    LearningComponent,
    AboutComponent,
    HighlightComponent,
    EventListComponent,
    OrganizationListComponent,
    CathaComponent,
    PrinsipComponent,
    NewsListComponent
  ]
})
export class PartialsModule { }
