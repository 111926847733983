import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  constructor() { }

  orgList = [
    {
      title: 'Dewan & Anggota Kehormatan  ICCN 2019 – 2022',
      img: 'assets/img/illustration/honorary.png',
      link: '/organisasi'
    },
    {
      title: 'Pengurus ICCN <br> 2019 – 2022',
      img: 'assets/img/illustration/board.png',
      link: '/organisasi'
    },
    {
      title: 'Mitra Dalam & Luar Negeri',
      img: 'assets/img/illustration/partner.png',
      link: '/'
    },
    {
      title: 'Kota Kreatif di Indonesia',
      img: 'assets/img/illustration/network.png',
      link: '/'
    }
  ];

  ngOnInit() {
  }

}
