import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { BackendComponent } from './backend.component';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { TopbarModule } from './components/topbar/topbar.module';
import { ErrorPageModule } from '../error-page/error-page.module';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  // },
  // {
  //   path: 'creativecities',
  //   loadChildren: () => import('./pages/creativecities/creativecities.module').then(m => m.CreativecitiesModule)
  // },
  // {
  //   path: 'collaborations',
  //   loadChildren: () => import('./pages/collaborations/collaborations.module').then(m => m.CollaborationsModule)
  // },
  // {
  //   path: 'organization',
  //   loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule)
  // },
  // {
  //   path: 'iccf',
  //   loadChildren: () => import('./pages/iccf/iccf.module').then(m => m.IccfModule)
  // },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule)
  },
  // {
  //   path: 'shop',
  //   loadChildren: () => import('./pages/shop/shop.module').then(m => m.ShopModule)
  // },
  // {
  //   path: 'user',
  //   loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
  // },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: '**',
    loadChildren: () => import('../error-page/error-page.module').then(() => ErrorPageModule)
  },
];

@NgModule({
  declarations: [BackendComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SidebarModule,
    TopbarModule,
    ConfirmDialogModule
  ],
  exports: [
    BackendComponent
  ]
})
export class BackendModule { }
