<section class="highlight bg-grey section-padding px-3 px-sm-0">

  <div class="container">

    <div class="row justify-content-center">
      <div class="col-lg-7 mb-5">
        <div class="card card-highlight card-highlight-video rounded-xl shadow">
          <div class="embed-responsive embed-responsive-1by1">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/u6wsuotDt-4" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div class="col-lg-7">
        <div class="row">
          <div class="col-12">
            <div class="card card-highlight card-highlight-media rounded-xl shadow">
              <a href="https://www.youtube.com/channel/UCBG8-I_qHRQxphOBowSOnvw" target="_blank" title="ICCN Media">
                <div class="row no-gutters">
                  <div class="col-4">
                    <div class="card-highlight-media__icon">
                      <i class="fas fa-fw fa-tv"></i>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="card-highlight-media__text">
                      <h3 class="mb-0">ICCN Media</h3>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- <div class="col-12">
            <div class="card card-highlight card-highlight-iccf rounded-xl shadow">
              <a [routerLink]="['/iccf']" title="Indonesia Creative Cities Festival">
                <img src="assets/img/illustration/iccf.png" class="card-highlight-iccf__img" alt="ICCF">
              </a>
            </div>
          </div> -->
        </div>
      </div>
    </div>

  </div>

</section>
