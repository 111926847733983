<section class="organization bg-grey section-padding o-hidden px-sm-0 px-3">

  <div class="container">

    <div class="organization__title text-center">
      <h2 class="section-title text-primary-1">Organisasi</h2>
      <p>Tim dan komunitas yang tergabung serta terlibat dalam ICCN</p>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-3 organization__item" *ngFor="let org of orgList">
        <div class="card card-organization border-0 rounded-xl shadow">
          <img src="{{org.img}}" class="card-organization__img">
          <div class="card-body card-organization__body">
            <h5 class="font-weight-bold card-title" innerHTML="{{org.title}}"></h5>
            <a routerLink="{{org.link}}" class="stretched-link">Klik Detail</a>
          </div>
        </div>
      </div>
    </div>

  </div>

</section>
