import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {

  constructor(private http: HttpClient) {
  }
  getClientIP(){
    return this.http.get<any>(`https://api.ipify.org/?format=json`);
  }
}
