import { Injectable, isDevMode } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpsInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const match = /^(\/(api|auth).+)/;
    const url = req.url.replace(match, isDevMode() ? `${environment.host}:${environment.port}$1` : `$1`)
    const secureReq = req.clone({url});
    return next.handle(secureReq);
  }
}
