<aside class="sidebar" tabindex="0">
  <div class="sidebar__trigger"></div>
  <div class="sidebar__heading">
    <!-- <h3 class="sidebar__heading-big">ICCN</h3> -->
    <img src="assets/img/others/avatar.webp" class="sidebar__heading-img" alt="Profile">
    <span class="sidebar__heading-small shadow">Admin</span>
  </div>
  <ul class="sidebar__menu">
    <ng-container *ngFor="let i of menu">
      <li [routerLink]="i.url" routerLinkActive="active">
        <i class="fas fa-fw {{i.icon}} mr-2"></i><span>{{i.name}}</span>
      </li>
    </ng-container>
  </ul>
</aside>
