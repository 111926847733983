import { Injectable } from '@angular/core';
import { Captcha } from './captcha';
import { HttpClient } from '@angular/common/http';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {
  constructor(
    private http: HttpClient,
    private helper: HelperService
  ) {}
  endpoint = '/api/captcha';
  validate(token) {
    const url = this.endpoint;
    return this.http.post<Captcha>(url, {token});
  }
}
