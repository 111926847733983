<section class="news bg-white section-padding px-sm-0 px-3">

  <div class="container">

    <div class="mb-5 text-center">
      <h2 class="section-title text-primary-1">Berita Terbaru</h2>
      <p>Berita dan Blog ICCN</p>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6 mb-4" *ngFor="let news of newsList">
        <div class="card card-news border-0 rounded-xl shadow">
          <div class="row no-gutters">
            <div class="col-md-5">
              <img [src]="helpers.parseImage(news.thumbnailImg)" class="card-news__img">
            </div>
            <div class="col-md-7">
              <div class="card-body">
                <h5 class="card-title">
                  <a [routerLink]="['/berita',news.slug]" title="{{news.title}}">{{news.title | shorten: 30: '...'}}</a>
                </h5>
                <p class="card-text">
                  {{helpers.stripHTML(news.content) | shorten: 50: '...'}}
                </p>
                <p class="card-text text-primary-1 font-weight-bold">{{news.publishDate | date:'EEEE, d MMMM yyyy'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 text-center">
      <a [routerLink]="['/berita']" class="btn btn-primary rounded-pill">Berita Lainnya</a>
    </div>

  </div>

</section>
