<section class="organization-list section-padding px-3 px-sm-0">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <section class="section-padding px-3 px-sm-0">
          <div class="container">
            <div class="text-center mb-5">
              <h2 class="section-title text-primary-1">
                Struktur Organisasi ICCN (2023 - 2025)
              </h2>
              <p>Dewan & Anggota Kehormatan</p>
            </div>

            <div class="row justify-content-center mb-3">
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/1_org.png"
                  alt="Wishnutaman Kusubandio"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Wishnutama Kusubandio </span>
                <span> Ketua Dewan Pengarah </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/mari-elka.jpeg"
                  alt="Mari Elka Pangestu"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Mari Elka Pangestu </span>
                <span> Anggota Dewan Pengarah </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/6_org.png"
                  alt="Rizky Adiwilaga"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Rizky Adiwilaga </span>
                <span> Ketua Dewan Pengawas </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/3_org.png"
                  alt="Triawan Munaf"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Paulus Mintarga </span>
                <span> Anggota Dewan Pengawas </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/liliek-setiawan.jpeg"
                  alt="Liliek Setiawan"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Liliek Setiawan </span>
                <span> Anggota Dewan Pengawas </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/8_org.png"
                  alt="Rizky Adiwilaga"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Erick Thohir </span>
                <span> Ketua Dewan Kehormatan </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/teten-masduki.jpeg"
                  alt="Rizky Adiwilaga"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Teten Masduki </span>
                <span> Anggota Kehormatan </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/angela-tanoesoedibjo.jpeg"
                  alt="Angela Tanoesoedibjo"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Angela Tanoesoedibjo </span>
                <span> Anggota Dewan Kehormatan </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/9_org.png"
                  alt="Idaman Andarmosoko"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> M. Ridwan Kamil </span>
                <span> Anggota Kehormatan </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/11_org.png"
                  alt="Rizky Adiwilaga"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Putri Tanjung </span>
                <span> Anggota Kehormatan </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/4_org.png"
                  alt="Indra Bigwanto"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Indra Bigwanto </span>
                <span> Anggota Kehormatan </span>
              </div>
              <div class="col-md-4 d-flex flex-column align-items-center pb-3 mt-3">
                <img
                  src="assets/img/org/7_org.png"
                  alt="Nicolas Buchoud"
                  class="org-img mb-3"
                />
                <span class="font-weight-bold"> Nicolas Buchoud </span>
                <span> Anggota Kehormatan </span>
              </div>
            </div>
          </div>
        </section>

        <hr />
        <div class="my-5">
          <h2>PENGURUS ICCN 2023-2025</h2>

          <div>
            <p class="mb-0">Ketua Umum</p>
            <ul>
              <li>Tb. Fiki C. Satari</li>
            </ul>
          </div>
          <div>
            <p class="mb-0">Wakil Ketua Umum</p>
            <ul>
              <li>Ignasius Galih Sedayu</li>
            </ul>
          </div>
          <div>
            <p class="mb-0">Executive Committee</p>
            <ul>
              <li>Dwinita Larasati</li>
              <li>Handoko Hendroyono</li>
              <li>Vicky Arief Herinadharma</li>
            </ul>
          </div>
          <div>
            <p class="mb-0">Staff Khusus Ketua Umum</p>
            <ul>
              <li>M. Rendra Manaba</li>
              <li>Hafshoh Mubarak</li>
              <li>Haris Satria</li>
            </ul>
          </div>
          <div>
            <p class="mb-0">Sekretaris Umum</p>
            <ul>
              <li>Ida Bagus Agung Gunarthawa</li>
            </ul>
          </div>
          <div>
            <p class="mb-0">Bendahara Umum</p>
            <ul>
              <li>Ira Shintia</li>
            </ul>
          </div>
          <div>
            <p class="mb-0">Biro 1 Administrasi Umum dan Keuangan</p>
            <ul>
              <li>Putri Syawala Tresnawati</li>
            </ul>
          </div>
          <div>
            <p class="mb-0">Biro 2 Big Data Monitoring dan Evaluasi</p>
            <ul>
              <li>Supriyadi</li>
              <li>Firmansyah</li>
            </ul>
          </div>
          <div>
            <p class="mb-0">Biro 3 Keanggotaan dan Legislasi</p>
            <ul>
              <li>Aldion Renata Pratama</li>
              <li>Alya Novinda</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">
              <span class="text-primary"
                >Deputi Hukum, Advokasi/Regulasi & HKI</span
              >: M. Aldi Novri Kurnia Abidin
            </p>
            <ul>
              <li>Sekretaris Deputi: Mayeksa Maqqdys</li>
              <li>Direktur Hukum: M. Philosophy</li>
              <li>Direktur HKI: Helitha Muchtar</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">
              <span class="text-primary"
                >Deputi Pendidikan, Riset & Pengembangan</span
              >: Mario Devys
            </p>
            <ul>
              <li>Sekretaris Deputi: Jeng Ayu Ning Tyas</li>
              <li>Direktur Riset & Pengembangan: Firman Venayaksa</li>
              <li>Direktur Kurikulum & Metodologi: I Made Artana</li>
              <li>Direktur Edukasi: Amar Alpabet</li>
              <li>Deputi Kemitraan Strategis: Melvi</li>
              <li>Sekretaris Deputi: Andre Muslim Dubari</li>
              <li>Direktur Kemitraan Dalam Negeri: Herman Umbu Billy</li>
              <li>Direktur Kemitraan Luar Negeri: Meirina Triharini</li>
              <li>Wadir. Kemitraan Perguruan Tinggi: Prananda L. Malasan</li>
              <li>Wadir. Kemitraan Pemerintahan: Sutanrai Abdillah</li>
              <li>Wadir. Kemitraan Bisnis: Romi Angger Hidayat</li>
              <li>
                Wadir Kemitraan Agregator & Asosiasi/Komunitas: Panca
                Dwinandhika Zen
              </li>
            </ul>
          </div>

          <div>
            <p class="mb-0">
              <span class="text-primary">Deputi Komunikasi Publik</span>: Affi
              Aditya Khresna
            </p>
            <ul>
              <li>Seskretaris Deputi: Vinca R. Natasha</li>
              <li>Publisher: Andesma Candra</li>
              <li>Direktur Strategi Media: Shafiq Pontoh</li>
              <li>Direktur Komunikasi Publik & Konten: Rendy Irlian Kamase</li>
              <li>Direktur Relasi Media: Kencana Wulansari Soselisa</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">
              <span class="text-primary">Deputi Kolaborasi Bisnis</span>: Komang
              Wena Wahyudi
            </p>
            <ul>
              <li>Sekretaris Deputi: Radityo S. Hartanto</li>
              <li>Direktur Analisa Bisnis & Investasi: R. Bagus Oka</li>
              <li>Direktur Aset & Keuangan Bisnis: Didi Diarsa</li>
              <li>Direktur Inkubator Bisnis: Dias Satria</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">
              <span class="text-primary"
                >Deputi Kelembagaan & Penguatan Jejaring</span
              >: Rafiuddin
            </p>
            <ul>
              <li>Sekretaris Deputi Regional Barat: Elmi CK Ong</li>
              <li>Sekretaris Deputi Regional Tengah: Trino</li>
              <li>Sekretaris Deputi Regional Timur: M. Ilham Mustain Murda</li>
              <li>Direktur Organisasi & Kelembagaan: Hilmi Fabeta</li>
              <li>Direktur Penguatan Jejaring & Kaderisasi: Zandri Aldrin</li>
            </ul>
          </div>

          <div>
            <p class="mb-0 text-primary">Task Force</p>
            <ul>
              <li>Web3: Adrian Zakhary</li>
              <li>Koperasi: Firdaus Putra</li>
              <li>Lembaga Pelatihan dan Sertifikasi: Gregorius Sri Wuryanto</li>
              <li>Jelonesia: Ardiansyah R. Akbar</li>
              <li>Event: Ashari Ramadhan Rivay</li>
              <li>Radio Multiplatform: M. Isra</li>
              <li>Rescue: Tb. Zainal Arifin</li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="mt-5">
          <h2>KOORDINATOR DAERAH ICCN 2013-2025</h2>

          <div>
            <p class="mb-0">Provinsi Aceh</p>
            <ul>
              <li>Irfan Maulana</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Sumatera Utara</p>
            <ul>
              <li>Erik Murdiyanto</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Kepulauan Riau</p>
            <ul>
              <li>Elmi CK Ong</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Sumatera Barat</p>
            <ul>
              <li>Yulviadi</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Riau</p>
            <ul>
              <li>R. Bagus Oka</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Bengkulu</p>
            <ul>
              <li>Firdaus Alamsyah</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Sumatera Selatan</p>
            <ul>
              <li>Yudi Suhairi</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Lampung</p>
            <ul>
              <li>Wisnu Wijaya</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Banten</p>
            <ul>
              <li>Andi Suhud</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Jawa Barat</p>
            <ul>
              <li>Robby Firliandoko</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi DIY</p>
            <ul>
              <li>Gregorius Sri Wuryanto</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Jawa Tengah</p>
            <ul>
              <li>Ahmad Khairudin</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Jawa Timur</p>
            <ul>
              <li>Upi Suprianto</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Bali</p>
            <ul>
              <li>Putu Sugi</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Nusa Tenggara Barat</p>
            <ul>
              <li>Lalu Chandra Yudhistira</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Nusa Tenggara Timur</p>
            <ul>
              <li>Ivan</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Kalimantan Barat</p>
            <ul>
              <li>Beny Than Heri</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Kalimantan Utara</p>
            <ul>
              <li>Ricci Solihin</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Kalimantan Tengah</p>
            <ul>
              <li>Wawan Setiawan</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Kalimantan Selatan</p>
            <ul>
              <li>Farid Faturrahman</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Sulawesi Barat</p>
            <ul>
              <li>Suryansyah</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Sulawesi Selatan</p>
            <ul>
              <li>Ashari Ramadhan</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Sulawesi Tengah</p>
            <ul>
              <li>Budi Hi Lolo</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Sulawesi Tenggara</p>
            <ul>
              <li>Supriyadi</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Sulawesi Utara</p>
            <ul>
              <li>Yunan Helmi</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Gorontalo</p>
            <ul>
              <li>Taufik Latama</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Maluku</p>
            <ul>
              <li>Olivia Jasso</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Maluku Utara</p>
            <ul>
              <li>Muhammad Ruka Harisun</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Papua</p>
            <ul>
              <li>Deny Jackson</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Papua Tengah</p>
            <ul>
              <li>Agustinus Kadepa</li>
            </ul>
          </div>

          <div>
            <p class="mb-0">Provinsi Papua Barat Daya</p>
            <ul class="mb-0">
              <li>Heinberto J.B Douglas Koirewa</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
