<section class="section-padding px-3 px-sm-0">

  <div class="container">

    <div class="row justify-content-center">
      <div class="col-lg-6 mb-4" *ngFor="let news of newsList">
        <div class="card card-news rounded-xl shadow">
          <div class="row no-gutters">
            <div class="col-md-5">
              <img [src]="helpers.parseImage(news.thumbnailImg)" class="card-news__img">
            </div>
            <div class="col-md-7">
              <div class="card-body">
                <h5 class="card-title">
                  <a [routerLink]="['/berita',news.slug]" title="{{news.title}}">{{news.title | shorten: 30: '...'}}</a>
                </h5>
                <p class="card-text">
                  {{helpers.stripHTML(news.content) | shorten: 50: '...'}}
                </p>
                <p class="card-text text-primary-1 font-weight-bold">{{news.publishDate | date:'EEEE, d MMMM yyyy'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <!-- <div class="row mt-5">
      <div class="col-12">
        <pagination class="justify-content-center" previousText="&laquo;" nextText="&raquo;" [totalItems]="155" [rotate]="true" [maxSize]="3"></pagination>
      </div>
    </div> -->
    <!-- Pagination -->

  </div>

</section>
