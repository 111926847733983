<section class="learning section-padding bg-white px-3 px-sm-0">

  <div class="container">

    <div class="row align-items-center">
      <div class="col-lg-6 mb-5 mb-lg-0">
        <h2 class="section-title text-primary-1 mb-3">AJAR Akademi Belajar ICCN</h2>
        <img src="assets/img/logo/ajar.png" class="learning__ajar-img" alt="AJAR - Akademi Belajar ICCN">
        <p class="mb-4 mb-md-5">
          ICCN berupaya untuk terus menginisiasi lahir dan bertumbuhnya kota serta kabupaten kreatif di Indonesia. Dengan program AJAR ini semua aktor terkait mulai dari akademisi, pelaku usaha, komunitas, pemerintah dan juga media bisa belajar bersama. Platform AJAR dijalankan secara online melalui kelas dan modul dan juga diselenggarakan secara khusus di kota Anda.
        </p>
        <!-- <div class="row">
          <div class="col-md-4 mb-3 mb-md-0" *ngFor="let i of cardLearning">
            <div class="card card-learning rounded-xl shadow">
              <a href="{{i.link}}" target="_blank">
                <div class="row no-gutters">
                  <div class="col-4">
                    <div class="card-learning__icon">
                      <i class="{{i.icon}}"></i>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="card-learning__text">
                      <h5 class="mb-0">{{i.text}}</h5>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-lg-6">
        <img src="assets/img/illustration/bukuputih.png" class="img-fluid d-block mx-auto" alt="">
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-4">
        <div class="card border-0 rounded-xl overflow-hidden shadow">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" class="about__video" poster="assets/img/others/ajar1.png" controls>
              <source src="assets/video/ajar1.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card border-0 rounded-xl overflow-hidden shadow">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" class="about__video" poster="assets/img/others/ajar2.png" controls>
              <source src="assets/video/ajar2.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card border-0 rounded-xl overflow-hidden shadow">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" class="about__video" poster="assets/img/others/ajar3.png" controls>
              <source src="assets/video/ajar3.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card border-0 rounded-xl overflow-hidden shadow">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" class="about__video" poster="assets/img/others/ajar4.png" controls>
              <source src="assets/video/ajar4.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card border-0 rounded-xl overflow-hidden shadow">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" class="about__video" poster="assets/img/others/ajar5.png" controls>
              <source src="assets/video/ajar5.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>

  </div>

</section>
