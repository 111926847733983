<section class="abbs section-padding bg-grey px-3 px-sm-0">

  <div class="container">

    <div class="text-center mb-3">
      <img src="assets/img/illustration/abbs.png" alt="icon aksi bersama bantu bersama" class="img-fluid mb-4" width="400px">
      <h2 class="section-title text-primary-1">Aksi Bersama Bantu Sesama</h2>
      <p>Program Kolaborasi Jejaring ICCN Tanggap COVID-19</p>
    </div>

    <div class="row align-items-center justify-content-center">
      <div class="col-sm-6 col-md-4 mb-5" *ngFor="let i of abbsList">
        <a href="{{i.linkURL}}" title="{{i.name}}">
          <img src="{{i.imgPath}}" alt="{{i.name}}" class="abbs__img">
        </a>
      </div>
    </div>

  </div>

</section>
