import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { ContactUsService } from 'src/app/services/contact-us.service';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { IpAddressService } from 'src/app/services/ip.address.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  private unsubs = new Subject()
  constructor(
    public helper: HelperService,
    private fb: FormBuilder,
    private contactUsService: ContactUsService,
    private sb: MatSnackBar,
    private ipService: IpAddressService,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      message: [null, [Validators.required]],
      ip: null,
      captcha: false
    })
  }
  showCaptcha = false;
  form: FormGroup;
  ngOnInit() {
    this.getIP()
  }
  send(){
    this.showCaptcha = true;
    if (this.form.valid && this.form.get('captcha').value) {
      this.sendMessage()
    }
  }
  captchaResolved(valid){
    this.form.get('captcha').setValue(valid)
    if(this.form.get('captcha').value){
      this.sendMessage()
    }
  }
  private sendMessage(){
    this.contactUsService.insert(this.form.value).pipe(takeUntil(this.unsubs))
      .subscribe(() => {
        this.sb.open('Pesan terkirim!', null, {duration: 5000})
        this.form.reset()
        this.showCaptcha = false;
      }, () => {
        this.showCaptcha = false;
        this.sb.open('Pesan gagal dikirim', null, {duration: 5000})
      })
  }
  private getIP() {
    this.ipService.getClientIP().pipe(takeUntil(this.unsubs)).subscribe(({ip}) => {
      this.form.get('ip').setValue(ip);
    });
  }
  ngOnDestroy() {
    this.unsubs.next()
    this.unsubs.complete()
  }
}
