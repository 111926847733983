import { Component, OnInit,Renderer2, Inject} from '@angular/core';
import { Subject } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { Events } from 'src/app/services/events';
import { EventsService } from 'src/app/services/events.service';
import { HttpQuery } from 'src/app/services/http-query';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  private subs = new Subject();
  where: Events = { isPublish: true};
  eventList: Events[] = []
  q: HttpQuery = { limit: 6 , offset: 0, sort: { eventDate: -1 }, where: this.where };

  constructor(
    private renderer: Renderer2,
    private eventsService: EventsService,
    public helpers: HelperService,
    // public router: Router
    ) {

  }

  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  ngOnInit() {
    this.getData()
    this.renderExternalScript("https://apps.elfsight.com/p/platform.js").onload = () => {
    // do something with this library
    }
  }

  getData() {
    // this.isLoading = true;
    // this.q.offset = (this.currentPage - 1 ) * this.q.limit;
    // this.q.limit = this.limit
    this.eventsService.getPublic(this.q)
    .pipe(takeUntil(this.subs))
    .subscribe(({data, count}) => {
      // console.log(data);

      // this.totalDataNews = count;
      this.eventList = data;
      // this.isLoading = false;
    }, (err) => {
      // this.isLoading = false;
      // this.errorMessage = err.message;
    });
  }

}
