import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactUsAPI, ContactUs } from './contact-us';
import { HelperService } from './helper.service';
import { HttpQuery } from './http-query';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  constructor(
    private http: HttpClient,
    private helper: HelperService
  ) {}
  endpoint = '/api/contact_us';
  getAll(query: HttpQuery) {
    const url = this.endpoint;
    const params = this.helper.generateParams(query);
    return this.http.get<ContactUsAPI>(url, {params});
  }
  getById(id: string) {
    const url = `${this.endpoint}/${id}`;
    return this.http.get<ContactUs>(url);
  }
  insert(data: ContactUs) {
    const url = this.endpoint;
    return this.http.post<ContactUs>(url, data);
  }
  updateById(id: string, data: ContactUs) {
    const url = `${this.endpoint}/${id}`;
    return this.http.put<ContactUs>(url, data);
  }
  removeById(id: string) {
    const url = `${this.endpoint}/${id}`;
    return this.http.delete<ContactUs>(url);
  }
}
