import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaDialogComponent } from './recaptcha-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [
    RecaptchaDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FlexLayoutModule,
    MatDialogModule
  ],
  exports: [
    RecaptchaDialogComponent
  ]
})
export class RecaptchaDialogModule { }
