<section class="about section-padding bg-white px-3 px-sm-0">

  <div class="container">

    <div class="row align-items-center mb-5">
      <div class="col-lg-6 order-2 order-lg-1">
        <!-- <img src="assets/img/illustration/about.png" class="about__img"> -->
        <img src="assets/img/illustration/1_kolaborasi.png" class="about__img">
      </div>
      <div class="col-lg-6 order-1 order-lg-2 mb-3 mb-lg-0">
        <!-- <h2 class="section-title text-primary-1 mb-3">ICCN, Jejaring Kota Kreatif Indonesia</h2>
        <div>
          <p>
            Indonesia Creative Cities Network (ICCN) atau Jejaring Kota/Kabupaten Kreatif Indonesia adalah simpul organisasi yang berkomitmen untuk mewujudkan 10 Prinsip Kota Kreatif. Kini dalam ICCN telah tergabung 211 inisiatif kota/kabupaten di bawah koordinasi 11 Koordinator Daerah yang tergabung dalam Pengurus Pusat ICCN.
          </p>
          <p>
            ICCN berkomitmen untuk memajukan kota-kota kreatif di Indonesia, dengan melakukan riset dan pengembangan untuk menumbuhkan pembangunan ekonomi dengan engine kreativitas (mesin penggerak kreativitas) yang berupa ide/gagasan kreatif dan inovatif yang ditopang oleh kelengkapan infrastruktur kelembagaan dalam keterlibatan unsur pentahelix (birokrasi, kademisi, bisnis, komunitas dan media) serta adanya dukungan infrastruktur digital yang berkualitas dan modern.
          </p>
        </div> -->
        <h2 class="section-title text-primary-1 mb-3">
          Padamu Negeri, Kami Berkolaborasi!
        </h2>
        <p>
          Indonesia Creative Cities Network (ICCN) adalah simpul jejaring Kota/Kabupaten Kreatif yang terbentuk sejak April 2015, dan dengan didasari oleh komitmen mewujudkan 10 Prinsip Kota Kreatif. Untuk mewujudkan 10 Prinsip Kota Kreatif tersebut, ICCN pun menciptakan jurus 11 Jurus Program Catha Ekadasa
        </p>
        <p>
          Jejaring ICCN terdiri dari elemen masyarakat yang menghidupkan Pentahelix Ekonomi Kreatif, yaitu Akademisi, Pengusaha / UMKM, Komunitas, Pemerintah, Media, dan Aggregator. Sampai kini jejaring ICCN merangkul inisiatif sampai 211+ Kota/Kabupaten Kreatif di seluruh Indonesia.
        </p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-6 mb-3 mb-md-0">
        <div class="card border-0 rounded-xl overflow-hidden shadow">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" class="about__video" poster="assets/img/others/cover.png" controls>
              <source src="assets/video/iccn-profile.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="card border-0 rounded-xl overflow-hidden shadow">
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" class="about__video" poster="assets/img/others/video_poster.png" controls>
              <source src="assets/video/sambutan_2.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div> -->
    </div>

  </div>

</section>
