<section class="contact bg-white section-padding o-hidden px-sm-0 px-3">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 mb-5 mb-lg-0">
        <div class="card p-3 rounded-xl border-0 shadow">
          <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="send()" novalidate>
              <h2 class="section-title card-title text-center mb-3">Hubungi Kami</h2>
              <div class="form-row form-group align-items-top">
                <div class="col-md-6">
                  <label class="font-weight-bold" for="name">Nama</label>
                  <input type="text" formControlName="name" class="form-control" name="name" id="name" required [ngClass]="{'is-invalid': helper.isInvalid(form, 'name')}">
                  <div class="invalid-feedback" *ngIf="helper.isInvalid(form, 'name')" >
                    Nama wajib diisi
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="font-weight-bold" for="email">Email</label>
                  <input type="email" formControlName="email" class="form-control" name="email" id="email" required [ngClass]="{'is-invalid': helper.isInvalid(form, 'email')}">
                  <div class="invalid-feedback" *ngIf="helper.isInvalid(form, 'email')" >
                    Email tidak sah
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="font-weight-bold" for="message">Pesan</label>
                <textarea name="message" id="message" formControlName="message" class="form-control" rows="3" required [ngClass]="{'is-invalid': helper.isInvalid(form, 'message')}"></textarea>
                <div class="invalid-feedback" *ngIf="helper.isInvalid(form, 'message')" >
                  Pesan wajib diisi
                </div>
              </div>
              <app-recaptcha-dialog *ngIf="showCaptcha" (resolved)="captchaResolved($event)"></app-recaptcha-dialog>
              <button type="submit" class="btn btn-primary btn-block rounded-pill mt-4" [disabled]="form.invalid">Kirim</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-5 offset-xl-1">
        <app-newsletter></app-newsletter>
      </div>
    </div>
  </div>
</section>
