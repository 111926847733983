<section class="catha bg-grey section-padding px-3 px-sm-0">

  <div class="container">

    <div class="row align-items-center">
      <div class="col-lg-5 mb-5 mb-lg-0">
        <h2 class="section-title text-primary-1 mb-3">11 Jurus Catha Ekadasa</h2>
        <p class="">
          Setiap kota memiliki potensi dan masalah yang unik, serta membutuhkan solusi yang tepat guna, ICCN telah memetakan formula yang dapat diaplikasikan sebagai solusi untuk pembangunan kota yang berorientasi pada kreativitas lokal berdasarkan pengalaman dan kisab baik dari inisiatif kota kreatif di Indonesia dan negara lain. Formula Catha Ekadasa terdiri dari 11 jurus yang solutif.
        </p>
        <ol class="mb-4 mb-md-5">
          <li>
            Forum Lintas Ekonomi Kreatif
          </li>
          <li>
            Komite Ekonomi Kreatif Pemerintah Daerah
          </li>
          <li>
            Ekosistem Kreatif Kota/Kabupaten (Iterasatari)
          </li>
          <li>
            Navigasi Pembangunan Kota
          </li>
          <li>
            Indeks Kota Kreatif
          </li>
          <li>
            Strategi Komunikasi & Narasi
          </li>
          <li>
            Creative City Branding Management
          </li>
          <li>
            Festival Kreatif
          </li>
          <li>
            Design Action / Musrenbang Kreatif
          </li>
          <li>
            Wirausaha Kreatif Desa X Kota
          </li>
          <li>
            Command Centre
          </li>
        </ol>
        <div class="catha__download">
          <i class="fas fa-fw fa-download"></i>
          <a href="https://drive.google.com/open?id=1KL8c3Hlclh_P9EJNCfNUqGLw_G2_F42x" target="_blank">
            Unduh Materi
          </a>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <img src="assets/img/illustration/3_jurus.png" class="catha__img">
      </div>
    </div>

  </div>

</section>
