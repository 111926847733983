import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpQuery } from './http-query';
import { HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private sb: MatSnackBar,
    private auth: AuthService,
    private sanitize: DomSanitizer
  ) {}
  pageSizeOptions = [5, 15, 25, 50, 100];
  pageTitle = new BehaviorSubject('');

  scrollObs = new BehaviorSubject('');
  search = new BehaviorSubject('');
  frontPage = new BehaviorSubject('');
  pagination$ = new BehaviorSubject({length: 0, page: null, pageIndex: 0});
  showPage$ = new BehaviorSubject(true);
  langObserver = new BehaviorSubject('en');

  sbError(message: string, action= null, autoClose= true): void {
    this.sb.open(message, action ? action : 'Galat', autoClose ? {duration: 5000} : {});
  }

  sbSuccess(message: string, action= null): void {
    this.sb.open(message, action ? action : 'Sukses', {duration: 5000});
  }

  generateParams(q: HttpQuery): HttpParams {
    let params = new HttpParams()
    .set('limit', q.limit.toString())
    .set('offset', q.offset.toString());
    if (q.sort) { params = this.toHttpParams(q.sort, params, 'sort'); }
    if (q.search) { params = params.append('search', q.search.toString()); }
    if (q.where) { params = this.toHttpParams(q.where, params, 'where'); }
    return params;
  }

  private toHttpParams(obj, params, type) {
    const keys = Object.keys(obj);
    if (keys.length) {
      keys.forEach((key) => {
        params = params.append(`${type}[${key}]`, obj[key]);
      });
    }
    return params;
  }
  generateError(errors) {
    let errMessage = '';
    if (errors) {
      errors.forEach((e) => {
        errMessage += `${e.param}: ${e.msg}. `;
      });
    }
    return errMessage;
  }


  currentUser() {
    if (localStorage.getItem(this.auth.localUser)) {
      return JSON.parse(localStorage.getItem(this.auth.localUser));
    }
    return null;
  }
  isInvalid(form: FormGroup, control: string): boolean {
    return (
      form.get(control).invalid &&
      (form.get(control).dirty || form.get(control).touched)
    );
  }
  parseImage(url){
    return isDevMode() ? `${environment.host}:${environment.port}/${url}` : `/${url}`
  }

  stripHTML(text: string ) {
    if (!text) {
        return text;
    }
    let without_html = text.replace(/<(?:.|\n)*?>/gm, ' ');
    return without_html;
  }

  sanitizeVideo(url){
    return this.sanitize.bypassSecurityTrustResourceUrl(url);
  }

}
