<nav class="navbar navbar-expand-lg navbar-dark bg-black shadow-sm fixed-top">

  <div class="container px-3">

    <a class="navbar-brand" href="#">
      <img src="assets/img/logo/logo_notext.png" alt="ICCN">
    </a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapseNavbar" [collapse]="isCollapsed" [isAnimated]="true">
      <ul class="navbar-nav font-weight-bold ml-auto">
        <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = true;scrollTo('home')">
          <a class="nav-link" [routerLink]="['/beranda']">Beranda</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/organisasi']">Organisasi</a>
        </li>
        <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = true;scrollTo('prinsip')">
          <a class="nav-link" [routerLink]="['/kotakreatif']">Kota Kreatif</a>
        </li>
        <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = true;scrollTo('yearly')">
          <a class="nav-link" [routerLink]="['/iccf']">ICCF</a>
        </li>
        <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = true">
          <a class="nav-link" href="https://fightcovid19.iccn.or.id/
          " target="_blank">Aksi Bersama Bantu Sesama</a>
        </li>
        <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = true;scrollTo('news')">
          <a class="nav-link" [routerLink]="['/berita-utama']">Berita</a>
        </li>
        <!-- <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = true">
          <a class="nav-link" [routerLink]="['/shop']">Toko</a>
        </li> -->
        <li class="nav-item" routerLinkActive="active" (click)="isCollapsed = true;scrollTo('contact')">
          <a class="nav-link" [routerLink]="['/kontak']">Kontak</a>
        </li>
        <!-- <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" href="#"><i class="fas fa-fw fa-search"></i></a>
        </li> -->
      </ul>
    </div>

  </div>

</nav>
