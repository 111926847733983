import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isCollapsed = true;
  constructor(private helper: HelperService) { }
  scrollTo(target) {
    this.helper.scrollObs.next(target);
  }

  ngOnInit() {
  }

}
