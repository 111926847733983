import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Indonesia Creative Cities Network';
  layout = 'frontend';
  isLoginPage = false;
  constructor(private router: Router) {
    this.router.events
    .pipe(filter((event => event instanceof NavigationEnd)))
    .subscribe((a: NavigationEnd) => {
      this.layout = (a.url.match(/\/(backend)(.+)?/gm)) ? 'backend' : 'frontend';
    });
  }
  onActivate(e: { constructor: { name: any; }; }) {
    if ((e.constructor.name) === "LoginPageComponent") {
      this.isLoginPage = true;
    }
    else {
      this.isLoginPage = false;
      window.scroll(0, 0);
    }
    // if ((e.constructor.name) === "HomePageComponent" ||
    //     (e.constructor.name) === "IccfPageComponent" ||
    //     (e.constructor.name) === "NewsPageComponent" ||
    //     (e.constructor.name) === "CreativecitiesPageComponent" ||
    //     (e.constructor.name) === "EventsPageComponent" ||
    //     (e.constructor.name) === "OrganizationPageComponent" ||
    //     (e.constructor.name) === "AjarPageComponent" ||
    //     (e.constructor.name) === "DampingPageComponent" ||
    //     (e.constructor.name) === "PulihPageComponent" ||
    //     (e.constructor.name) === "RescuePageComponent") {
    //   window.scroll(0, 0);
    // } else if ((e.constructor.name) === "LoginPageComponent") {
    //   this.isLoginPage = true;
    // }
  }


}
