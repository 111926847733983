import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PartialsModule } from './partials/partials.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { HttpInterceptors } from './interceptors';
import { BackendModule } from './backend/backend.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import localeId from '@angular/common/locales/id';
import localeIdrExtra from '@angular/common/locales/extra/id';
import { registerLocaleData } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppSharedModule } from './app-shared.module';
registerLocaleData(localeId, 'id', localeIdrExtra);
import { defineLocale } from 'ngx-bootstrap/chronos';
import { idLocale } from 'ngx-bootstrap/locale';
defineLocale('id', idLocale);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppSharedModule,
    BackendModule,
    PartialsModule,
    ScrollToModule.forRoot(),
  ],
  providers: [
    HttpInterceptors,
    Title,
    Meta,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LfiHvcUAAAAANNT3Z8atpA1VhhNnwrO2aofEvC9' } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'id',
    },
    { provide: LOCALE_ID, useValue: "id-ID" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
