import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'beranda',
    pathMatch: 'full'
  },
  {
    path: 'beranda',
    loadChildren: () => import('./frontend/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'org',
    loadChildren: () => import('./frontend/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'kotakreatif',
    loadChildren: () => import('./frontend/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'organisasi',
    loadChildren: () => import('./frontend/organization-page/organization-page.module').then(m => m.OrganizationPageModule)
  },
  {
    path: 'iccf',
    loadChildren: () => import('./frontend/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'beranda',
    loadChildren: () => import('./frontend/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'berita-utama',
    loadChildren: () => import('./frontend/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'berita',
    loadChildren: () => import('./frontend/news-page/news-page.module').then(m => m.NewsPageModule)
  },
  // {
  //   path: 'toko',
  //   loadChildren: () => import('./shop-page/shop-page.module').then(m => m.ShopPageModule)
  // },
  {
    path: 'acara',
    loadChildren: () => import('./frontend/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'daftar-acara',
    loadChildren: () => import('./frontend/events-page/events-page.module').then(m => m.EventsPageModule)
  },
  {
    path: 'kontak',
    loadChildren: () => import('./frontend/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'ajar',
    loadChildren: () => import('./frontend/ajar-page/ajar-page.module').then(m => m.AjarPageModule)
  },
  {
    path: 'damping',
    loadChildren: () => import('./frontend/damping-page/damping-page.module').then(m => m.DampingPageModule)
  },
  {
    path: 'pulih',
    loadChildren: () => import('./frontend/pulih-page/pulih-page.module').then(m => m.PulihPageModule)
  },
  {
    path: 'rescue',
    loadChildren: () => import('./frontend/rescue-page/rescue-page.module').then(m => m.RescuePageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./frontend/login-page/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'backend',
    canActivate:[AuthGuard],
    loadChildren: () => import('./backend/backend.module').then(m => m.BackendModule)
  },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
