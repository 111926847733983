<section class="error px-3 px-sm-0">

  <div class="container">
    <div>
      <h1 class="display-1 font-weight-bold text-center">404</h1>
      <p class="text-center">Halaman tidak ditemukan</p>
    </div>
  </div>

</section>
