import { Component, OnInit } from '@angular/core';
import { OrganizationList } from './organization';
@Component({
  selector: 'app-oranization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {

  constructor() { }
  boardList = OrganizationList;
  ngOnInit(): void {
  }

}
