import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaptchaService } from '../services/captcha.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-recaptcha-dialog',
  templateUrl: './recaptcha-dialog.component.html',
  styleUrls: ['./recaptcha-dialog.component.scss']
})
export class RecaptchaDialogComponent implements OnInit {
  @Output() resolved = new EventEmitter()
  constructor(
    private fb: FormBuilder,
    private captchaService: CaptchaService,
    private sb: MatSnackBar
  ) {
    this.form = this.fb.group({
      resolved: [null, [Validators.required]]
    })
  }
  form: FormGroup;
  ngOnInit(): void {
    this.form.get('resolved').valueChanges.subscribe(isValid => {
      if (isValid) {
        this.captchaService.validate(isValid).subscribe(res => {
         if(res.success)  {
           this.resolved.emit(true)
         } else {
          this.resolved.emit(false)
         }
        }, err => {
          this.sb.open(err.message)
        })
      }
    })
  }
}
