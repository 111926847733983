import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  constructor(
    private auth:AuthService,
    private helper: HelperService
  ) { }
  pageTitle: string;

  logout(){
    this.auth.logout();
  }

  ngOnInit(): void {
    this.helper.pageTitle.subscribe(title => {
      this.pageTitle = title;
    });
  }

}
